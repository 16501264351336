<template>
  <div class="detail">
    <div class="location">
      <i class="el-icon-place"></i>
      <el-breadcrumb
        class="el__breadcrumb"
        separator-class="el-icon-arrow-right"
      >
        <el-breadcrumb-item>您当前的位置</el-breadcrumb-item>
        <el-breadcrumb-item
          :to="{
            path: page ? '/web/online/' + page + '/' + type : '/web/online/1/1',
          }"
          >在线问政</el-breadcrumb-item
        >
        <el-breadcrumb-item>详情</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="content">
      <div class="left">
        <div class="head">
          <div class="title">
            {{ detail.title }}
          </div>
          <div class="status-img">
            <el-image
              v-if="detail.status >= 20"
              class="pos__icon"
              :src="require('images/online/reply.png')"
            ></el-image>
            <el-image
              v-if="detail.status == 10"
              class="pos__icon"
              :src="require('images/online/accept.png')"
            ></el-image>
            <el-image
              v-if="detail.status == 1 || detail.status == 6"
              class="pos__icon"
              :src="require('images/online/reject.png')"
            ></el-image>
          </div>
          <div class="sub">
            发帖人：{{ detail.userName }} | 发布日期：{{ detail.handelTime }} |
            {{ detail.deptName }} | {{ detail.userIpName }}
          </div>
        </div>
        <div class="desc" v-html="detail.content"></div>
        <div class="img-list">
          <el-image
            v-for="(item, index) in imgList"
            :key="index"
            class="img"
            :src="item"
            :preview-src-list="imgList"
            fit="cover"
          ></el-image>
        </div>
        <div class="video-list">
          <div v-for="(item, index) in videoList" :key="index" class="video">
            <div class="play-btn" @click="playVideo(item)">
              <img src="@/assets/images/detail/video.png" />
            </div>
            <img :src="item + '?x-oss-process=video/snapshot,t_10000,m_fast'" />
          </div>
        </div>

        <div class="like" @click="like">
          <img
            :src="
              require(`images/detail/${detail.hasLike ? 'like' : 'unlike'}.png`)
            "
          />
          <span>{{ detail.likes }}</span>
        </div>
        <div class="reply" v-if="detail.status >= 20">
          <div class="pos_tag">官方回复</div>
          <div v-for="(item, index) in detail.replyList" :key="index">
            <div class="name">{{ detail.deptName }}</div>
            <div
              class="txt"
              v-html="item.content"
              @click="showImg($event)"
            ></div>
            <div class="next">
              回复于 {{ item.createTime }}
              <span>{{ detail.replyIp.split("==")[1] }}</span>
            </div>
          </div>
          <!-- <div class="name">{{ detail.deptName }}</div>
          <div
            class="txt"
            v-html="detail.replyContent"
            @click="showImg($event)"
          ></div>
          <div class="next">
            回复于 {{ detail.replyTime }}
            <span>{{detail.replyIp.split("==")[1]}}</span>
          </div> -->
        </div>
        <div class="bottom">
          <div class="label">我来回答</div>
          <div class="login" v-if="userInfo">
            <el-input
              v-model="content"
              type="textarea"
              :rows="4"
              placeholder="文明上网、理性发言，营造良好网络环境"
            >
            </el-input>
            <div class="submit" @click="addComment">发布</div>
          </div>
          <div class="unlogin" v-else>
            <div class="lt">请 <span>登录/注册</span> 后进行评论或回复</div>
            <el-button @click="login">立即登录</el-button>
          </div>
        </div>
        <div class="comments">
          <div class="label">全部评论</div>
          <ul>
            <li v-for="item in comments" :key="item.id">
              <el-image
                class="avatar"
                :src="
                  item.avatar
                    ? item.avatar
                    : require('images/common/avatar.png')
                "
              ></el-image>
              <div class="main">
                <div class="top">
                  <div class="lt">
                    <span class="name">{{ item.userName || "匿名" }}</span>
                    <span class="time">{{ item.createTime }}</span>
                    <span class="ip">{{ item.userIpName }}</span>
                  </div>
                  <div class="rt" @click="commentLike(item)">
                    <img
                      :src="
                        require(`images/detail/${
                          item.hasLike ? 'like' : 'unlike'
                        }.png`)
                      "
                    />
                    <span>{{ item.likes }}</span>
                  </div>
                </div>
                <pre class="txt">{{ item.content }}</pre>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="right">
        <el-timeline>
          <el-timeline-item
            v-if="detail.feedbackName !== '' && detail.feedbackName"
            color="#5CBD31"
          >
            <div>
              用户评价：<span class="feedbackName">{{
                detail.feedbackName
              }}</span>
            </div>
          </el-timeline-item>
        </el-timeline>
        <el-timeline>
          <el-timeline-item
            v-for="(activity, index) in wzasksRecords"
            :key="index"
            :timestamp="activity.createTime"
            :color="detail.feedbackName === '' ? activity.color : ''"
          >
            <span>{{ activity.operation }}</span>
          </el-timeline-item>
        </el-timeline>
      </div>
    </div>

    <el-dialog :visible.sync="dialogVisible">
      <video
        width="100%"
        style="max-height: 70vh"
        :src="dialogFileUrl"
        controls
      />
    </el-dialog>
    <el-image-viewer
      v-if="shareSwitch"
      :on-close="
        () => {
          shareSwitch = false;
        }
      "
      :url-list="shareUrl"
    />
  </div>
</template>

<script>
import { getAskDetail } from "@/api/wzasksevent";
import { getWzasksRecord } from "@/api/wzasksrecord";
import { wzaskslike } from "@/api/wzaskslike";
import { getWzaskscomments, addComments } from "@/api/wzaskscomments";
import { htmlToText } from "@/utils/tools";
import { mapState } from "vuex";

export default {
  name: "detail",
  components: {
    "el-image-viewer": () =>
      import("element-ui/packages/image/src/image-viewer"),
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.userInfo,
    }),
    id() {
      return this.$route.query.id;
    },
    page() {
      return this.$route.query.page;
    },
    type() {
      return this.$route.query.type;
    },
  },
  data() {
    return {
      content: "",
      detail: {},
      wzasksRecords: [],
      comments: [],

      addCommentBtn: true,
      imgList: [],
      videoList: [],
      dialogVisible: false,
      dialogFileUrl: "",

      shareSwitch: false,
      shareUrl: [],
    };
  },
  watch: {
    shareSwitch: {
      handler(val) {
        if (val) {
          const m = (e) => {
            e.preventDefault();
          };
          document.body.style.overflow = "hidden";
          document.addEventListener("touchmove", m, false); // 禁止页面滑动
        } else {
          const m = (e) => {
            e.preventDefault();
          };
          document.body.style.overflow = "auto";
          document.removeEventListener("touchmove", m, true);
        }
      },
    },
  },
  mounted() {
    this.getDetail();
    this.getWzasksRecord();
    this.getWzaskscomments();

  },
  methods: {
    async addComment() {
      if (!this.addCommentBtn) return;
      if (!this.content) return this.$message.error("请输入评论内容");
      const { id } = this.detail;
      this.addCommentBtn = false;
      const res = await addComments(this.content, id, 0, 1);
      this.addCommentBtn = true;
      // console.log(res)
      res && this.$message.success("评论成功,待管理员审核通过可见");
      this.content = "";
    },
    async commentLike(item) {
      const res = await wzaskslike(item.id, 2);
      // console.log(res)
      this.$message.success(res.msg);
      item.likes = item.hasLike ? --item.likes : ++item.likes;
      item.hasLike = !item.hasLike;
    },
    login() {
      this.$router.push("/web/login");
    },
    async getWzaskscomments() {
      const res = await getWzaskscomments(this.id, 1);
      // console.log(res);
      this.comments = res.data.records.reverse();
    },
    async like() {
      const res = await wzaskslike(this.id, 1);
      // console.log(res)
      this.$message.success(res.msg);
      this.detail.likes = this.detail.hasLike
        ? --this.detail.likes
        : ++this.detail.likes;
      this.detail.hasLike = !this.detail.hasLike;
      // this.getDetail();
    },
    async getDetail() {
      const res = await getAskDetail(this.id);
      console.log(res);
      if (!res) this.$router.replace("/404");
      if (
        (res.data.status === 0 && res.data.userId !== this.userInfo.user_id) ||
        (res.data.isPublic === 0 &&
          res.data.userId !== this.userInfo.user_id) ||
        res.data.isDeleted > 0
      ) {
        this.$router.replace("/404");
      }
      res.data.content = htmlToText(res.data.content);
      res.data.content = res.data.content.replaceAll("\n", "<br>");
      // console.log(res.data.content)
      this.detail = res.data;
      document.title = this.detail.title;
      if (res.data.imgUrl !== "") {
        this.imgList = res.data.imgUrl.split(",");
      }
      if (res.data.videoUrl !== "") {
        this.videoList = res.data.videoUrl.split(",");
      }
    },
    async getWzasksRecord() {
      const res = await getWzasksRecord(this.id);
      // console.log(res);
      this.wzasksRecords = res.data;
      if (this.wzasksRecords.length) {
        this.wzasksRecords[0].color = "#5CBD31";
      }
    },
    // 播放视频
    playVideo(url) {
      this.dialogVisible = true;
      this.dialogFileUrl = url;
    },

    showImg(e) {
      if (e.target.tagName == "IMG") {
        console.log(e.target.src);
        if (e.target.src) {
          this.shareUrl[0] = e.target.src;
          this.shareSwitch = true;
        }
      }
    }
  },
  beforeDestroy(){
    document.title = "改革在身边 最多跑一次——通衢网络问政平台";
  }
};
</script>

<style lang="scss" scoped>
pre {
  white-space: pre-wrap; /*css-3*/
  white-space: -moz-pre-wrap; /*Mozilla,since1999*/
  white-space: -pre-wrap; /*Opera4-6*/
  white-space: -o-pre-wrap; /*Opera7*/
  word-wrap: break-word; /*InternetExplorer5.5+*/
}
.detail {
  background: #ffffff;
  .location {
    padding: 20px;
    display: flex;
    align-items: center;
    .el__breadcrumb {
      margin-left: 8px;
    }
  }
  .content {
    padding: 30px 50px 70px;
    display: flex;
    .left {
      width: 780px;
      display: flex;
      flex-direction: column;
      padding-right: 26px;
      .head {
        position: relative;
        border-bottom: 1px solid #eeeeee;
        padding-bottom: 20px;
        text-align: center;
        .title {
          font-size: 24px;
          font-weight: 700;
          color: #2a2b2e;
        }
        .status-img {
          position: absolute;
          width: 100px;
          top: 0;
          right: 0;
        }
        .sub {
          font-size: 14px;
          color: #999999;
          margin-top: 26px;
        }
      }
      .desc {
        font-size: 15px;
        color: #555555;
        line-height: 32px;
        margin-top: 14px;
        /deep/ img {
          width: 100%;
        }
      }
      .img-list {
        margin-top: 50px;
        display: flex;
        .img {
          width: 148px;
          height: 148px;
          border-radius: 5px;
          margin: 0 10px 10px 0;
          border: 1px dotted #eeeeee;
        }
      }
      .video-list {
        display: flex;
        .video {
          position: relative;
          width: 148px;
          height: 148px;
          border-radius: 5px;
          margin: 0 10px 10px 0;
          border: 1px dotted #eeeeee;
          overflow: hidden;
          .play-btn {
            position: absolute;
            margin: auto;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            // transform: translate(-50%, -50%);
            width: 50px;
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            background-color: rgba(0, 0, 0, 0.2);
            cursor: pointer;
            transition: 0.5s;
            img {
              width: 50%;
              height: 50%;
            }
          }
          .play-btn:hover {
            transform: scale(1.5);
            transform-origin: center;
            background-color: rgba(0, 0, 0, 0.8);
          }
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
      .like {
        width: 138px;
        height: 44px;
        border: 1px solid #ff281b;
        font-size: 20px;
        color: #ff281b;
        border-radius: 46px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 50px auto;
        cursor: pointer;
        img {
          width: 20px;
          margin-right: 8px;
        }
      }
      .reply {
        background: #f9fafc;
        padding: 60px 30px 24px 50px;
        position: relative;
        .pos_tag {
          position: absolute;
          top: -10px;
          left: 20px;
          width: 136px;
          height: 40px;
          background: url("~images/detail/bg.png") center center no-repeat;
          background-size: cover;
          font-size: 20px;
          color: #ffffff;
          text-align: center;
          line-height: 40px;
        }
        .name {
          font-size: 18px;
          font-weight: 700;
          color: #2a2b2e;
        }
        .txt {
          margin-top: 18px;
          line-height: 1.4;
        }
        .next {
          font-size: 13px;
          color: #90949e;
          margin-top: 15px;
          margin-bottom: 30px;
        }
      }
      .bottom {
        margin-top: 48px;
        .label {
          font-size: 18px;
          color: #2a2b2e;
          display: flex;
          align-items: center;
          font-weight: 700;
          &::before {
            content: "";
            display: block;
            width: 4px;
            height: 22px;
            margin-right: 10px;
            background: #5cbd31;
          }
        }
        .unlogin {
          margin-top: 22px;
          background: #fff9f9;
          border: 1px dashed #d03333;
          padding: 32px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .lt {
            font-size: 14px;
            color: #999999;
            span {
              color: #ff281b;
            }
          }
          .el-button {
            width: 108px;
            height: 38px;
            background: #d03333;
            border-radius: 2px;
            font-size: 14px;
            color: #ffffff;
            border: 0;
          }
        }
        .login {
          margin-top: 22px;
          display: flex;
          .el-textarea {
            width: 670px;
            /deep/ .el-textarea__inner {
              border: 1px solid #dcdfe6;
              border-radius: 0;
            }
          }
          .submit {
            flex: 1;
            background: #5cbd31;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            color: #ffffff;
            cursor: pointer;
          }
        }
      }
      .comments {
        margin-top: 40px;
        .label {
          font-size: 18px;
          color: #2a2b2e;
          display: flex;
          align-items: center;
          font-weight: 700;
          &::before {
            content: "";
            display: block;
            width: 4px;
            height: 22px;
            margin-right: 10px;
            background: #5cbd31;
          }
        }
        ul {
          margin-top: 20px;
          li {
            display: flex;
            // align-items: center;
            padding: 20px 0;
            &:not(:last-child) {
              box-shadow: 0px -1px 0px 0px #eeeeee inset;
            }
            .avatar {
              width: 50px;
              height: 50px;
              // padding: 10px;
              background: #f9fafc;
              border-radius: 25px;
            }
            .main {
              flex: 1;
              padding-left: 20px;
              .top {
                display: flex;
                justify-content: space-between;
                align-items: center;
                .lt {
                  display: flex;
                  align-items: center;
                  .name {
                    font-size: 13px;
                    color: #335db0;
                  }
                  .time,
                  .ip {
                    font-size: 13px;
                    color: #999999;
                    margin-left: 14px;
                  }
                }
                .rt {
                  display: flex;
                  align-items: center;
                  font-size: 13px;
                  color: #9c9da1;
                  img {
                    width: 14px;
                    margin-right: 4px;
                  }
                }
              }
              .txt {
                font-size: 14px;
                color: #4b4b4b;
                line-height: 24px;
                margin-top: 8px;
              }
            }
          }
        }
      }
    }
    .right {
      flex: 1;
      padding-left: 28px;
      box-shadow: 1px 0px 0px 0px #eeeeee inset;
      .feedbackName {
        color: #5cbd31;
      }
    }
  }
}
</style>

<style lang="scss">
.detail .content .left .reply .txt {
  img {
    max-width: 200px !important;
  }
}
</style>
